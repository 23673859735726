<template>
<div>
    <v-skeleton-loader
      :loading="loadingTable"
      :transition="'fade-transition'"
      height="500"
      type="table"
    >
      <v-data-table
        :headers="getHeaders(typesNotHeader)"
        :items="dataTable"
        :items-per-page="dataTable.length"
        hide-default-footer
        dense
        item-class="teste"
        :search="searchLocal"
        :mobile-breakpoint="300"
        class="box-table"
        :custom-filter="customFilter"
      >
        <template
          v-slot:body="{ items }"
        >

        <tbody>
            <tr>
              <th>Total</th>
              <th class="text-end">{{ getTotal(items, 'anterior') | currencyNoSymbol }}</th>
              <th class="text-end">{{ getTotal(items, 'movimento') | currencyNoSymbol }}</th>
              <th class="text-end">{{ getTotal(items, 'atual') | currencyNoSymbol }}</th>
            </tr>
          
            <tr
              v-for="item in items"
              :key="item.entity_id"
              :class="{ cancelado: isDeleted(item) }"
              class="box-table-row"
              @click="maisDetalhes(item)"
            >
              <td>
                <v-btn
                  elevation="1"
                  plain
                  raised
                  small
                  tile
                  @click.stop="opemModalEntidade(item)"
                  :height="20"
                >
                  <v-tooltip top v-if="isDeleted(item)">
                    <template v-slot:activator="{ on, attrs }">
                      <span 
                        v-bind="attrs"
                        v-on="on"
                        class="text-truncate text-name"
                      >
                        {{ item.entity_cod }} <span class="mobile">- {{ item.entity_name }}</span>
                      </span>
                    </template>
                    <span>{{ item.entity_deleted | datas('DD/MM/YYYY') }}</span>
                  </v-tooltip>
                  <span v-else class="text-truncate text-name">
                    {{ item.entity_cod }}
                    <span class="mobile">- {{ item.entity_name }}</span>
                  </span>
                </v-btn>
              </td>
              <td class="text-end">{{ item.deve_ant | currencyNoSymbol }}</td>
              <td class="text-end">{{ item.movimento | currencyNoSymbol }}</td>
              <td class="text-end">{{ item.deve_pending | currencyNoSymbol }}</td>
              <!-- <td class="text-end">{{ item.pagto | currencyNoSymbol }}</td> -->
              <td class="text-end">
                <v-btn
                  fab
                  :width="20"
                  :color="item.deve_pending <= 0 ? variables.colorPrimary : variables.colorPositiveGecom"
                  class="btn-remove"
                  dark
                >
                  <v-icon :size="14">mdi-plus</v-icon>
                </v-btn>

              </td>
            </tr>

            <tr>
              <!-- <th>Total</th> -->
              <!-- <th class="text-end mobile">{{ totais.vendas_ant | currencyNoSymbol }}</th>
              <th class="text-end">{{ totais.vendas | currencyNoSymbol }}</th>
              <th class="text-end mobile">{{ totais.caixa | currencyNoSymbol }}</th>
              <th class="text-end mobile">{{ totais.pagto | currencyNoSymbol }}</th>
              <th class="text-end">{{ totais.balance | currencyNoSymbol }}</th> -->
            </tr>
          </tbody>
        </template>
      </v-data-table>
      
    </v-skeleton-loader>
    <v-card class="mt-3">
      <v-card-text class="pt-0 pb-0">
        <v-row>
          <v-col cols="12" sm="12">
            <div class="d-flex mb-2">
              <strong class="mr-2" :style="{color: '#1cb4ff'}" >Anterior:</strong> Dev. Anterior.
            </div>
            <div class="d-flex mb-2">
              <strong class="mr-2" :style="{color: '#1cb4ff'}">Movimento:</strong> Lançamentos de Prê-Caixa e Pagamentos.
            </div>
            <div class="d-flex">
              <strong class="mr-2" :style="{color: '#1cb4ff'}">Atual:</strong> Dev. Atual.
            </div>
          </v-col>
        </v-row>
        
      </v-card-text>
    </v-card>
</div>
</template>

<script>
import variables from '@/assets/styles/helpers/_variables.scss'
export default {
  name: 'TabelaRelatorioGecom',
  props: {
    dataTable: {
      type: Array,
      default: () => {},
      required: true
    },

    loadingTable: {
      type: Boolean,
      default: false
    },

    typesNotHeader: {
      type: Array,
      default: () => []
    },

    typeGecom: {
      type: Number,
      default: null
    },

    configGecom: {
      type: Object,
      default: () => {}
    },

    search: {
      type: String,
      required: true
    },

    date: {
      type: String,
      required: true
    }
  },
  data: () => ({
    searchLocal: ''
  }),

  mounted () {
    this.searchLocal = this.search
  },

   watch: {
     'search' (val) {
       this.searchLocal = val
     }
   },

  computed: {
    headersData () {
      return [
        { align: 'start', width: 50, class: 'table-header', cellClass: 'text-truncate', text: this.getTextHeaderTypeGecom(this.typeGecom), value: 'cod' },
        { filter: false, align: 'end', class: 'table-header', text: 'Anterior', value: 'deve_ant' },
        { filter: false, align: 'end', class: 'table-header', text: 'Movimento', value: 'movimento' },
        { filter: false, align: 'end', class: 'table-header', text: 'Atual', value: 'deve_atual' },
        { filter: false, align: 'end', class: 'table-header', text: '', value: '' },
      ]
    },

    variables: () => variables
  },

  methods: {
    formatName (item) {
      return item.json_data ? `(${item.json_data.cod}) ${item.json_data.name}` : item.name
    },

    getHeaders (typesNotHeader) {
      return this.headersData.filter(item => {
        return !typesNotHeader.includes(item.value)
      })
    },

    openProposta(val) {
      this.$emit('openProposta', val)
    },

    opemModalEntidade(val) {
      this.$emit('openModalEntidade', val)
    },

    getTextHeaderTypeGecom (typeGecom) {
      switch (typeGecom) {
        case null:
          return 'Supervisor'
        case 2:
          return 'Vendedor'
        case 3:
        case 4:
          return 'RV'
        default:
          return 'RV'
      }
    },

    getColor (item) {
      const vendas = parseInt(item.deve_ant)
      const vendasAnterior = parseInt(item.deve_atual)
      if (!vendas) return this.variables.colorError

      const percentage = (vendas / vendasAnterior) * 100
      const { success, warning } = this.configGecom.percentageSales
      if (percentage >= success) {
        return this.variables.colorSecondary
      }

      if (percentage >= warning) {
        return this.variables.colorWarning
      }

      return this.variables.colorError
    },

    isDark (item) {
      const vendas = parseInt(item.deve_ant)
      const vendasAnterior = parseInt(item.deve_atual)
      if (!vendas) return true

      const percentage = (vendas / vendasAnterior) * 100
      const { success, warning } = this.configGecom.percentageSales
      if (percentage >= success) {
        return true
      }

      if (percentage >= warning) {
        return false
      }

      return true
    },

    isDeleted (data) {
      if (data.entity_deleted > '1970-01-01'){
        return true
      }

      return false
    },

    customFilter (value, search, item) {
      return item != null &&
        search != null &&
        item.entity_cod.toString().indexOf(search) !== -1
    },

    maisDetalhes (item) {

      if (item.parent_path.split('.').length < 4) {
        this.$emit('getMaisDetalheGefin', item.parent_path)
      } else {
        this.$router.push({ name: 'dados-revenda', params: { entity: item, date: this.date } })
      }
    },

    getTotal (items, type) {
      switch(type) {
        case 'anterior':
          return items.reduce((acc, { deve_ant }) => acc + parseFloat(deve_ant ? deve_ant : 0), 0)
        case 'movimento':
          return items.reduce((acc, { movimento }) => acc + parseFloat(movimento ? movimento : 0), 0)
        case 'atual':
          return items.reduce((acc, { deve_pending }) => acc + parseFloat(deve_pending ? deve_pending : 0), 0)
        default:
          return 0
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .text-name {
    display: inline-block;
    line-height: 0.9;

    @media (min-width: 420px) {
      width: 115px!important;
    } 
  }

  .cadastro-usuario >>> .v-data-table__wrapper table {
    min-width: auto;
  }

  .box-table::v-deep {

    table {
      min-width: 320px;
    }

    .cancelado {
      color: #F00;
    }

    .mobile {
      @media (max-width: 420px) {
        display: none;
      } 
    }

    .v-btn {
      height: 20px !important;

    }
  }
  
</style>